import * as React from 'react';
import { bindActionCreators } from 'redux';
import { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { userMessageActions } from '@@services/usermessageactions';
import { States } from '@@services/services';
import { communityEntityDatasourceV3 } from '@@community/api';
import type { IMembershipLevel } from '@@modules/community/data';
import { VisibilitySelector, IVisibilitySelectorProps } from './visibilityselector';
import { VisibilityDisplay } from './visibilitydisplay';

import "./visibilitypicker.less";

export interface IVisibility {
    allowPublic: boolean;
    hideForUnallowed?: boolean;
    restricted: Record<string, boolean>;
}

export interface IVisibilityPickerProps extends IVisibility{
    onChange: (visibility: IVisibility) => void;
    community?: States.ICommunityState;
    userMessageActions?: typeof userMessageActions;
    i18nHelper?: Entities.i18nHelper;
    hidePartialContentCheckbox?: boolean;
}

export interface IVisibilityPickerState {
    levels: IMembershipLevel[];
}

@connectwith((state: States.IAppState) => {
    return {
        community: state.community
    };
}, (dispatch) => {
    return {
        userMessageActions: bindActionCreators(userMessageActions, dispatch)
    };
})
@withI18nHelper()
export class VisibilityPicker extends React.PureComponent<IVisibilityPickerProps, IVisibilityPickerState> {
    constructor(props) {
        super(props);
        this.state = {
            levels: null
        };
    }

    componentDidMount() {
        if (!this.state.levels) {
            communityEntityDatasourceV3<IMembershipLevel>(
                this.props.community.requestManagers.apiFront, "membershiplevel", "MembershipLevel"
            ).then((ds) => {
                ds.query({
                    order: [{ by: "name" }]
                }).then((res) => {
                    this.setState({
                        levels: res.data
                    });
                });
            }).then(null, (err) => {
                console.error("error loading levels", err);
            });
        }
    }

    pickVisibility = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        const props: IVisibilitySelectorProps = {
            community: this.props.community,
            allowPublic: this.props.allowPublic,
            hideForUnallowed: this.props.hideForUnallowed,
            restricted: this.props.restricted,
            levels: this.state.levels,
            hidePartialContentCheckbox: this.props.hidePartialContentCheckbox
        };

        (this.props.userMessageActions.showMessage(
            this.props.i18nHelper,
            VisibilitySelector,
            props,
            arg.currentTarget,
            null,
            null,
            "community-visibilityselectorpopover"
        ) as any).then((res) => {
            if (res) {
                this.props.onChange(res);
            }
        });
    };

    render() {
        return <button
            type="button"
            className="community-visibilitypicker clickable bloc-lightborder"
            onClick={this.pickVisibility}
        >
            <VisibilityDisplay
                levels={this.state.levels}
                allowPublic={this.props.allowPublic}
                restricted={this.props.restricted}
            />
            <span className="chevron">
                <i className="inwink-chevron-down" />
            </span>
        </button>;
    }
}