import { IMembershipLevel } from '@@modules/community/data';
import { AppTextLabel } from '@inwink/i18n';
import * as React from 'react';
import "./visibilityselector.less";

export interface IVisibilityDisplayProps {
    allowPublic: boolean;
    restricted: Record<string, boolean>;
    levels: IMembershipLevel[];
}

export interface IVisibilityDisplayState {
}

export class VisibilityDisplay extends React.PureComponent<IVisibilityDisplayProps, IVisibilityDisplayState> {
    render() {
        let content = null;
        let restricted = this.props.restricted && Object.keys(this.props.restricted);
        if (restricted && restricted.length === 0) {
            restricted = null;
        }

        if (this.props.allowPublic) {
            content = <AppTextLabel i18n="community.feed.visibility.public" />;
        } else if (!restricted) {
            content = <AppTextLabel i18n="community.feed.visibility.allmembers" />;
        } else {
            content = <span>{restricted.map((k, idx) => {
                const membership = this.props.levels?.find((m) => m.id === k);
                if (membership) {
                    return <span key={membership.id}>{membership.name}{idx < restricted.length - 1 ? ", " : ""}</span>;
                }
                return null;
            })}</span>;
        }

        return <span className="community-visibilitydisplay">
            {content}
        </span>;
    }
}
