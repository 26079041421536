import type { ICommunityRequestManager } from '../../../services/apiaccessprovider.definition';

export function communityEntityDatasourceV3<T>(
    requestManager: ICommunityRequestManager,
    entityroute: string,
    entityname: string
) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontCommunityEntityDataSource<T>(
            requestManager, entityroute, entityname
        );
    });
}
