import { AppTextLabel } from '@inwink/i18n';
import { Loader } from '@inwink/loader';
import * as React from 'react';
import { States } from '@@services/services';
import { IMembershipLevel } from '@@community/data';
import { communityEntityDatasourceV3 } from '@@community/api';
import type { IPopoverDismissManager } from '@inwink/modals';
import { Tooltip } from '@inwink/modals/tooltip';

import "./visibilityselector.less";

export interface IVisibilitySelectorProps {
    community: States.ICommunityState;
    levels?: IMembershipLevel[];
    allowPublic: boolean;
    hideForUnallowed: boolean;
    restricted: Record<string, boolean>;
    onCompleted?: (res?) => void;
    popover?: IPopoverDismissManager;
    hidePartialContentCheckbox?: boolean;
}

declare type IVisibility = "public" | "allmembers" | "custom";
export interface IVisibilitySelectorState {
    visibility: IVisibility;
    allowPublic: boolean;
    hideForUnallowed: boolean;
    restricted: Record<string, boolean>;
    levels?: IMembershipLevel[];
    ready: boolean;
}

export class VisibilitySelector extends React.PureComponent<IVisibilitySelectorProps, IVisibilitySelectorState> {
    constructor(props: IVisibilitySelectorProps) {
        super(props);
        let visibility: IVisibility = "allmembers";
        if (props.allowPublic) {
            visibility = "public";
        } else if (props.restricted && Object.keys(props.restricted).length > 0) {
            visibility = "custom";
        }

        this.state = {
            visibility,
            hideForUnallowed: props.hideForUnallowed || false,
            allowPublic: props.allowPublic || false,
            restricted: props.restricted,
            levels: props.levels,
            ready: !!props.levels
        };
    }

    componentDidMount() {
        if (!this.state.levels) {
            communityEntityDatasourceV3<IMembershipLevel>(
                this.props.community.requestManagers.apiFront, "membershiplevel", "MembershipLevel"
            ).then((ds) => {
                ds.query({
                    order: [{ by: "name" }]
                }).then((res) => {
                    this.setState({
                        levels: res.data,
                        ready: true
                    }, () => {
                        setTimeout(() => {
                            this.props.popover.refreshLayout();
                        }, 200);
                    });
                });
            }).then(null, (err) => {
                console.error("error loading levels", err);
            });
        }
    }

    setPublic = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        this.setState({
            allowPublic: true,
            restricted: null
        });
    };

    setAllMembers = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        this.setState({
            allowPublic: false,
            restricted: null
        });
    };

    validate = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        if (this.props.onCompleted) {
            this.props.onCompleted({
                allowPublic: this.state.allowPublic,
                restricted: this.state.restricted,
                hideForUnallowed: this.state.hideForUnallowed
            });
        }
    };

    visibilityChanged = (arg: React.FormEvent<HTMLSelectElement>) => {
        const vis: IVisibility = arg.currentTarget.value as any;
        if (vis === "public") {
            this.setState({
                hideForUnallowed: false,
                allowPublic: true,
                restricted: null
            });
        }
        if (vis === "allmembers") {
            this.setState({
                allowPublic: false,
                restricted: null
            });
        }
        if (vis === "custom") {
            this.setState({
                allowPublic: false,
                restricted: null
            });
        }
        this.setState({ visibility: vis }, () => {
            this.props.popover.refreshLayout();
        });
    };

    hideForUnallowedChanged = (arg: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            hideForUnallowed: !arg.currentTarget.checked
        });
    };

    hideForUnallowedTooltip = () => {
        return <AppTextLabel i18n="community.feed.visibility.hideforunallowed.info" />;
    };

    render() {
        let content = null;
        if (!this.state.ready) {
            content = <Loader />;
        } else {
            content = <div className="items">
                <select className="visibilitykind" value={this.state.visibility} onChange={this.visibilityChanged}>
                    <AppTextLabel component="option" i18n="community.feed.visibility.public" value="public" />
                    <AppTextLabel component="option" i18n="community.feed.visibility.allmembers" value="allmembers" />
                    <AppTextLabel component="option" i18n="community.feed.visibility.custom" value="custom" />
                </select>
                {this.state.visibility === "custom" ? <div className="memberships">
                    <AppTextLabel i18n="community.feed.visibility.custom.desc" component="p" className="remark" />
                    <div className="membershipitems bloc-lightborder">
                        {this.state.levels?.map((l) => {
                            const selected = (this.state.restricted && this.state.restricted[l.id]) === true;
                            return <div key={l.id}>
                                <input
                                    type="checkbox"
                                    checked={selected}
                                    id={"mem" + l.id}
                                    onChange={(arg) => {
                                        const checked = arg.currentTarget.checked;
                                        // arg.preventDefault();
                                        this.setState((prevstate) => {
                                            const res = Object.assign({}, prevstate, {
                                                allowPublic: false,
                                                restricted: Object.assign({}, prevstate.restricted, {
                                                    [l.id]: checked
                                                })
                                            });

                                            if (!checked) {
                                                delete res.restricted[l.id];
                                            }
                                            if (Object.keys(res.restricted).length === 0) {
                                                (res as any).restricted = null;
                                            }

                                            return res;
                                        });
                                    }}
                                />
                                <label htmlFor={"mem" + l.id}>
                                    {l.name}
                                </label>
                            </div>;
                        })}
                    </div>
                </div> : null}
                {(this.state.visibility !== "public" && !this.props.hidePartialContentCheckbox) ? <>
                    <div className="hideforunallowed">
                        <input
                            id="hideforunallowed"
                            type="checkbox"
                            checked={!this.state.hideForUnallowed}
                            onChange={this.hideForUnallowedChanged}
                        />
                        <AppTextLabel
                            i18n="community.feed.visibility.hideforunallowed"
                            component="label"
                            htmlFor="hideforunallowed"
                        />
                        <Tooltip component="div" className="infotooltip bloc-lightborder" content={this.hideForUnallowedTooltip}>
                            <i className="inwink-idea" />
                        </Tooltip>
                    </div>
                </> : null}
            </div>;
        }

        return <div className="community-visibilityselector">
            <header>
                <AppTextLabel
                    i18n="community.feed.visibility.title"
                    component="h2"
                />
                <AppTextLabel
                    i18n="community.feed.visibility.desc"
                    className="remark"
                    component="p"
                />
            </header>
            <div className="community-visibilityselector-content">
                {content}
            </div>
            <div className="actions">
                <button onClick={this.validate} type="button">
                    <AppTextLabel i18n="actions.validate" />
                </button>
            </div>
        </div>;
    }
}